// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'application |||| application',
        title: 'Enregistrer un nouveau sinistre',
        success: 'Votre rapport de sinistre a été envoyé avec succès',
        fields: {
            numberId: 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Devise',
            createdAt: 'Date de sinistre',
            dealer: 'Société',
            contactFirstName: 'Prénom',
            contactLastName: 'Nom de famille',
            street: 'Rue',
            zip: 'Code postal',
            city: 'Ville',
            country: 'Pays',
            claimDate: 'Date de sinistre',
            mileage: 'Kilométrage',
            labourCost: 'Prix Travail',
            partsCost: 'Prix Pièces',
            totalCost: 'Prix Totaux',
            damageDescription: 'Description du sistre',
            diagnosis: 'Cause/ Diagnose',
            repairDescription: 'Reparation à effectuer',
            costCalculationFile: 'Veuillez télécharger le calcul correspondant',
            vehicleRegistrationFile: 'Veuillez télécharger les documents d\'inscription',
            serviceConfirmationFile: 'Veuillez télécharger le document de service',
            tcsInspectionFile: 'Veuillez télécharger le TCS Rapport de test du véhicule',
            status: 'Statut ',
        },
        buttons: {
            submit: 'Envoyer',
            cancel: 'Annuler',
        },
        blocks: {
            reporter: 'Contact concessionnaire',
            contact_person: 'Contact',
            address: 'Adresse',
            vehicle: 'Véhicule',
            damage_report: 'Détail des sinistres',
        },
        status: {
            new: 'En attente d\'approbation',
            rejected: 'Rejeté',
            awaiting_second_approval: 'En attente de la deuxième approbation',
            awaiting_dealer_rectification: 'En attente de rectification par le concessionnaire',
            accepted: 'Accepté, en attente de la facture',
            awaiting_invoice_approval: 'En attente de l\'approbation de la facture',
            invoice_accepted: 'Facture acceptée',
            invoice_refused: 'facture refusée : Attente d\'une facture actualisée',
            paid: 'Pay',
        },
        action: {
            partly_accept:      'Accepté sous condition',
            accept:             'Autorisation donnée',
            refuse:             'Refuser',
            reject:             'Rejeter',
            update_claim_data:  'Données de réclamation mises à jour',
            submit_invoice:     'Envoyer la facture',
            accept_invoice:     'Accepter la facture',
            refuse_invoice:     'Facture refusée',
            payment_done:       'Paiement effectu',
        },
        actionComments: {
            partly_accept:      'Selon les limites de libération, la demande sera vérifiée par un deuxième expert.',
            accept:             'Dès que vous cliquez sur "Transmettre", vous donnez au garagiste l\'autorisation de procéder à la réparation.',
            refuse:             'Veuillez fournir des informations sur le refus',
            reject:             'Veuillez indiquer la raison du refus ainsi que les nouveaux critères d\'acceptation',
            update_claim_data:  'Mise à jour des données relatives aux réclamations',
            submit_invoice:     'Envoyer la facture',
            accept_invoice:     'Dès qu\'ils cliquent sur transmettre, le demandeur est informé que la facture est en cours de traitement.',
            refuse_invoice:     'Veuillez fournir des informations sur le refus',
            payment_done:       'Dès que vous cliquez sur transmettre, le demandeur est informé que la facture a été payée',
        },
        comment: 'commentaire'
    }
}