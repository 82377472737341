// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'Anwendung |||| Anwendungen',
        title: 'Neuen Schaden registrieren',
        success: 'Ihre Schadensmeldung wurde erfolgreich gesendet',
        fields: {
            numberId: 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Währung',
            createdAt: 'Schadendatum',
            dealer: 'Firma',
            contactFirstName: 'Vorname',
            contactLastName: 'Nachname',
            street: 'Strasse',
            zip: 'Postleitzahl',
            city: 'Ort',
            country: 'Land',
            claimDate: 'Schadendatum',
            mileage: 'Kilometerstand',
            labourCost: 'Kosten Arbeit',
            partsCost: 'Kosten Teile',
            totalCost: 'Kosten Total',
            damageDescription: 'Schadenbeschreibung',
            diagnosis: 'Ursache / Diagnose',
            repairDescription: 'Reparaturbeschrieb',
            costCalculationFile: 'Bitte laden Sie die entsprechende Berechnung hoch',
            vehicleRegistrationFile: 'Bitte laden Sie die Registrierungspapiere hoch',
            serviceConfirmationFile: 'Bitte laden Sie die Servicebestätigungen hoch',
            tcsInspectionFile: 'Bitte laden Sie den TCS Prüfbericht hoch',
            status: 'Status',
        },
        buttons: {
            submit: 'Senden',
            cancel: 'Stornieren',
        },
        blocks: {
            reporter: 'Händler Kontakt',
            contact_person: 'Kontaktperson',
            address: 'Adresse',
            vehicle: 'Fahrzeug',
            damage_report: 'Schadendetails',
        },
        status: {
            new: 'Warte auf Freigabe',
            rejected: 'Zurückgewiesen',
            awaiting_second_approval: 'Warte auf Zweit-Freigabe',
            awaiting_dealer_rectification: 'Warte auf Berichtigung durch Händler',
            accepted: 'Freigegeben - Warten auf Rechnung',
            awaiting_invoice_approval: 'Warte auf Rechnungsfreigabe',
            invoice_accepted: 'Rechnung akzeptiert ',
            invoice_refused: 'Rechnung abgelehnt: Warte auf neue Rechnung',
            paid: 'Bezahl',
        },
        action: {
            partly_accept:      'Bedingt akzeptiert',
            accept:             'Freigabe erteilt ',
            refuse:             'Abgelehnt',
            reject:             'Zurückgewiesen',
            update_claim_data:  'Schadendaten aktualisiert',
            submit_invoice:     'Rechnung übermitteln',
            accept_invoice:     'Rechnung akzeptieren',
            refuse_invoice:     'Rechnung abgelehnt',
            payment_done:       'Zahlung ausgeführ',
        },
        actionComments: {
            partly_accept:      'Gemäss der Freigabelimits, wird der Antrag noch von einem 2ten Experten geprüft.',
            accept:             'Sobald Sie übermitteln klicken, erteilen Sie dem Händler die Reperaturfreigabe.',
            refuse:             'Bitte geben Sie den Grund der Ablehnung an.',
            reject:             'Bitte geben Sie den Grund der Zurückweisung sowie die neuen Akzeptanzkriterien an',
            update_claim_data:  'Schadendaten angepasst',
            submit_invoice:     'Rechnung übermittelt',
            accept_invoice:     'Sobald sie übermitteln klicken, wird der Händler informiert, dass die Rechnung in Bearbeitung ist.',
            refuse_invoice:     'Bitte geben Sie den Grund der Ablehnung an.',
            payment_done:       'Sobald sie übermitteln klicken, wird der Händler informiert, dass die Rechnung bezahlt wurde',
        },
        comment: 'Kommentar'
    },
}