// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "damage-reports": {
        name: 'application |||| application',
        title: 'Registrare un nuovo danno',
        success: 'Il tuo rapporto sui danni è stato inviato con successo',
        fields: {
            numberId: 'Damage ID',
            warrantyStart: 'Warranty start',
            warrantyEnd: 'Warranty end',
            totalClaims: 'Numbers of claims elapsed',
            mandantCompany: "Mandant",
            currency: 'Valuta',
            createdAt: 'Data di sinistro',
            dealer: 'Società',
            contactFirstName: 'Nome',
            contactLastName: 'Cognome',
            street: 'Strada',
            zip: 'Codice postale',
            city: 'Città',
            country: 'Nazione',
            claimDate: 'Data di sinistro',
            mileage: 'Chilometraggio',
            labourCost: 'Prezzo Lavoro',
            partsCost: 'Prezzo Parti',
            totalCost: 'Prezzo Totale',
            damageDescription: 'Descrizione del sinistro',
            diagnosis: 'Causa / Diagnosi',
            repairDescription: 'Riparazione da eseguire',
            costCalculationFile: 'Carica il calcolo corrispondente',
            vehicleRegistrationFile: 'Carica i documenti di registrazione',
            serviceConfirmationFile: 'Carica il documento di servizio',
            tcsInspectionFile: 'Carica il documento di TCS Rapporto di prova del veicolo',
            status: 'Stato ',
        },
        buttons: {
            submit: 'Inviaree',
            cancel: 'Annulla',
        },
        blocks: {
            reporter: 'Contatto del concessionario',
            contact_person: 'Contatta',
            address: 'Indirizzo',
            vehicle: 'Veicolo',
            damage_report: 'Dettagli del danno',
        },
        status: {
            new: 'In attesa di approvazione',
            rejected: 'Rifiutato',
            awaiting_second_approval: 'In attesa della seconda approvazione',
            awaiting_dealer_rectification: 'In attesa della rettifica del concessionario',
            accepted: 'Accettato, in attesa di fattura',
            awaiting_invoice_approval: 'In attesa dell\'approvazione della fattura',
            invoice_accepted: 'Fattura accettata',
            invoice_refused: ' fattura rifiutata: In attesa della fattura aggiornata',
            paid: 'Pagat',
        },
        action: {
            partly_accept:      'Condizionatamente accettato',
            accept:             'Autorizzazione data',
            refuse:             'Rifiuti',
            reject:             'Rifiuta',
            update_claim_data:  'Dati di reclamo aggiornati',
            submit_invoice:     'Invia fattura',
            accept_invoice:     'Fattura accettata',
            refuse_invoice:     'fattura rifiutata',
            payment_done:       'Pagamento effettuat',
        },
        actionComments: {
            partly_accept:      'In base ai limiti di rilascio, l\'applicazione sarà verificata da un secondo esperto.',
            accept:             'Non appena cliccate Submit, date al proprietario del garage l\'approvazione della riparazione.',
            refuse:             'Si prega di fornire informazioni sul rifiuto',
            reject:             'Si prega di indicare il motivo del rifiuto e i nuovi criteri di accettazione',
            update_claim_data:  'Dati di reclamo aggiornati',
            submit_invoice:     'Invia fattura',
            accept_invoice:     'Una volta cliccato su invia, il richiedente viene informato che la fattura è in fase di elaborazione.',
            refuse_invoice:     'Si prega di fornire informazioni sul rifiuto',
            payment_done:       'Non appena si clicca su Invia, il richiedente viene informato che la fattura è stata pagata',
        },
        comment: 'commento'
    }
}