import authForm from './authForm/it';
import menu from './menu/it';
import resources from './resources/it';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    "app.loading" : "Chargement",
    "app.welcome" : 'Bienvenue',
    "app.form.required" : 'Champ obligatoire',
    "app.form.submit" : 'Transmettre',
    "app.form.next" : 'Seguente',
    show : 'Afficher',
    hide : 'Cacher',

    "app.checking" : 'vérification en cours',
    "app.gender.male" : 'mâle',
    "app.gender.female" : 'femelle',
    "app.company.customer" : 'client',
    "app.company.provider" : 'Provider',
    "app.company.dealer" : "Concessionnaire",

    roles: {
        ROLE_ANONYMOUS : 'Utilisateur anonyme',
        ROLE_ADMIN : 'Administrateur',
        ROLE_EMPLOYEE : 'Employé',
        ROLE_COMPANY_ADMIN : 'Administrateur de société',
        ROLE_COMPANY_MANAGER : 'Manager d\'entreprise',
        ROLE_BRANCH_MANAGER : 'Manager de branche',
        ROLE_DEALER : 'Revendeur',
        ROLE_CLAIMS_MANAGER : 'Claims manager',
    },

    dashboard : {
        logged_in_as : 'Enregistré comme',
        role : 'Rôle',
        from_date : 'Date de',
        to_date : 'Date jusqu\'au',
    },

    ...authForm,
    ...menu,
    ...resources

}