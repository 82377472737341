// eslint-disable-next-line import/no-anonymous-default-export
import theme from "../theme/default/config";
export default {
  api: process.env.REACT_APP_API_ENTRYPOINT,
  login: process.env.REACT_APP_LOGIN_ENTRYPOINT,
  login_vin_code: process.env.REACT_APP_LOGIN_VIN_CODE_ENTRYPOINT,
  login_uuid: process.env.REACT_APP_LOGIN_UUID_ENTRYPOINT,
  root:  process.env.REACT_APP_API_ROOT,
  languages: [
    { id: 'ru', name: 'Русский' },
  ],
  themeConfig: theme,
}